exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".E6xJM {\n  padding: 10px 20px 20px;\n}\n.E6xJM .dZhqW {\n  border-bottom: 1px solid #CBCBCB;\n  padding: 5px 10px;\n}\n.E6xJM .dZhqW label {\n  font-size: 1rem !important;\n  width: 130px !important;\n}\n.E6xJM .dZhqW label {\n  text-transform: capitalize;\n}\n.E6xJM .dZhqW ._3xeJB {\n  margin-left: 145px;\n}\n.E6xJM .dZhqW ._3xeJB input {\n  border-bottom: 0;\n}\n.E6xJM .dZhqW ._3xeJB input::-webkit-input-placeholder {\n  text-transform: capitalize;\n}\n.E6xJM .dZhqW ._3xeJB input::-moz-placeholder {\n  text-transform: capitalize;\n}\n.E6xJM .dZhqW ._3xeJB input:-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.E6xJM .dZhqW ._3xeJB input::-ms-input-placeholder {\n  text-transform: capitalize;\n}\n.E6xJM .dZhqW ._3xeJB input::placeholder {\n  text-transform: capitalize;\n}\n.E6xJM ._3nijn {\n  margin-top: 5px;\n  margin-left: 165px;\n}\n.E6xJM ._2h407 {\n  margin-top: 20px;\n  text-align: center;\n}\n.E6xJM ._2h407 button {\n  margin-left: 5px;\n  margin-right: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "E6xJM",
	"formGroup": "dZhqW",
	"formControls": "_3xeJB",
	"validationError": "_3nijn",
	"buttons": "_2h407"
};