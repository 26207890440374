import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import loc from 'Components/languages';
import { getWarehouse, getWarehouseByFilter } from 'Redux/actions';
import WarehouseListItem from './WarehouseListItem.jsx';
import styles from './ModalWarehouse.less';

class ModalWarehouse extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			size: 1000,
			keyword: '',
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const { page, size, keyword } = this.state;
		const { filter } = this.props;
		let param = {
			payload: {
				page,
				size,
			},
		};
		if (keyword) {
			param.payload.keyword = keyword;
		}
		if (filter) {
			param.payload.filter = filter;
			this.props.getWarehouseByFilter(param);
		} else {
			this.props.getWarehouse(param);
		}
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	render() {
		const { isOpen, onToggle, onSelect, title, filter } = this.props;
		const { keyword } = this.state;
		const warehouseInfo = filter ? this.props.warehouseByFilterInfo : this.props.warehouseInfo;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ title }
				style={ this.modalStyle }
			>
				<div className={ styles.desc }>
					{ loc.changeWarehousePopupDesc }
				</div>
				<div className={ styles.searchContainer }>
					<form onSubmit={ this.onSubmitHandler }>
						<div className={ styles.search }>
							<input
								type="text"
								value={ keyword }
								placeholder={ loc.typeAndSearch }
								className={ styles.input }
								onChange={ this.onChangeKeywordHandler }
							/>
							<div className={ styles.icon }>
								<FontAwesomeIcon icon={ faSearch } />
							</div>
						</div>
						<div className={ styles.btnSearch }>
							<button
								type="submit"
								disabled={ warehouseInfo.isFetching }
								className="uk-button uk-button-small uk-button-secondary"
							>
								{ loc.search }
							</button>
						</div>
					</form>
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							warehouseInfo.isFetching ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								warehouseInfo.data && warehouseInfo.data.data && warehouseInfo.data.data.map((warehouse, index) => (
									<WarehouseListItem
										key={ `modal-warehouse-info-${index}` }
										warehouse={ warehouse }
										onSelect={ onSelect }
									/>
								))
							)
						}
					</ul>
				</div>
			</Modal>
		);
	}
}

ModalWarehouse.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	filter: PropTypes.string,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default connect(
	(state) => ({
		warehouseInfo: state.warehouseInfo,
		warehouseByFilterInfo: state.warehouseByFilterInfo,
	}),
	(dispatch) => ({
		getWarehouse: para => dispatch(getWarehouse(para)),
		getWarehouseByFilter: para => dispatch(getWarehouseByFilter(para)),
	})
)(ModalWarehouse);