import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import Modal from 'Components/modal';
import Button from 'Components/button';
import LoadingOverlay from 'Components/loading-overlay';
import styles from './ModalApproval.less';

class ModalApproval extends PureComponent {
	constructor() {
		super();
		this.state = {
			user_id: '',
			code: '',
			remark: '',
			error: null,
		};
		this.modalStyle = {
			content: {
				top: 'calc(50% - 179px)',
				bottom: 'calc(50% - 179px)',
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				height: '385px',
				padding: 0,
			},
		};
		this.isLoading = this.isLoading.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onClickCancelHandler = this.onClickCancelHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen && isOpen !== prevIsOpen) {
			this.setState({
				user_id: '',
				code: '',
				remark: '',
				error: null,
			});
		}

		const approvalInfo = this.props.approvalInfo;
		const prevApprovalInfo = prevProps.approvalInfo;
		if (approvalInfo && approvalInfo !== prevApprovalInfo) {
			let error = null;
			if (
				approvalInfo.type  === cons.POST_APPROVAL.SUCCESS &&
				approvalInfo.data &&
				!approvalInfo.data.is_verfied
			) {
				error = loc.MSGIncorrectApprovalCode;
			} else if (
				approvalInfo.type  === cons.POST_APPROVAL.FAILURE &&
				approvalInfo.error
			) {
				error = loc[approvalInfo.error.code] || approvalInfo.error.message;
			}
			this.setState({ error });
		}
	}

	isLoading() {
		const { approvalInfo, voidOrderInfo } = this.props;
		return approvalInfo.isFetching || voidOrderInfo.isFetching;
	}

	onChangeHandler(e) {
		let newState = {};
		newState[e.target.id] = e.target.value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { onSubmit } = this.props;
		const { user_id, code, remark } = this.state;
		onSubmit({
			user_id,
			code,
			remark,
		});
	}

	onClickCancelHandler() {
		this.setState({
			user_id: '',
			code: '',
			remark: '',
		}, this.props.onToggle);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		const { user_id, code, remark, error } = this.state;
		const isLoading = this.isLoading();
		const isInvalid = !!error;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.supervisorApproval }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading }>
					<div className={ styles.container }>
						<form className="uk-form-horizontal" onSubmit={ this.onSubmitHandler }>
							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.username }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<input
										id="user_id"
										className="uk-input"
										type="text"
										value={ user_id }
										disabled={ isLoading }
										placeholder={ loc.username }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>
							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.approvalCode }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<input
										id="code"
										className="uk-input"
										type="password"
										value={ code }
										disabled={ isLoading }
										placeholder={ loc.approvalCode }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>
							{
								isInvalid && (
									<div className={ classnames('uk-text-small', 'uk-text-danger', styles.validationError) }>
										{ error }
									</div>
								)
							}
							<div className={ styles.formGroup }>
								<label className="uk-form-label uk-text-primary">
									{ loc.remark }
								</label>
								<div className={ classnames('uk-form-controls', styles.formControls) }>
									<textarea
										id="remark"
										className="uk-textarea"
										rows={ 4 }
										value={ remark }
										disabled={ isLoading }
										placeholder={ loc.remark }
										onChange={ this.onChangeHandler }
									/>
								</div>
							</div>
							<div className={ styles.buttons }>
								<Button
									theme="default"
									text={ loc.cancel }
									type="button"
									onClick={ this.onClickCancelHandler }
								/>
								<Button
									theme="primary"
									text={ loc.confirm }
									type="submit"
									disabled={ user_id === '' || code === '' || isLoading }
								/>
							</div>
						</form>
					</div>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalApproval.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		approvalInfo: state.approvalInfo,
		voidOrderInfo: state.voidOrderInfo,
	})
)(ModalApproval);