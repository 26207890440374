import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import queryString from 'query-string';
import printJS from 'print-js';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import Button from 'Components/button';
import loc from 'Components/languages';
import { getTransferDetails, confirmTransferInOrder, postApproval, voidOrder, printTransferOrder } from 'Redux/actions';
import { receiveApi } from 'Helpers/helpers';
import * as cons from 'Redux/constants';
import ModalApproval from '../order-search/components/ModalApproval.jsx';
import StockListItem from './components/StockListItem.jsx';
import styles from './PageStockTransferOrder.less';

class PageStockTransferOrder extends PureComponent {
	constructor() {
		super();
		this.state = {
			showModalApproval: false,
		};
		this.getParams = this.getParams.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.isLoading = this.isLoading.bind(this);
		this.setIsVerified = this.setIsVerified.bind(this);
		this.onClickCheckInHandler = this.onClickCheckInHandler.bind(this);
		this.onClickVoidHandler = this.onClickVoidHandler.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
		this.onClickPrintHandler = this.onClickPrintHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();

		let param = queryString.parse(this.props.location.search);
		if (param.print) {
			this.onClickPrintHandler();
		}
	}

	componentDidUpdate(prevProps) {
		const transferInOrderConfirmInfo = this.props.transferInOrderConfirmInfo;
		const prevTransferInOrderConfirmInfo = prevProps.transferInOrderConfirmInfo;
		const transferPdfInfo = this.props.transferPdfInfo;
		const prevTransferPdfInfo = prevProps.transferPdfInfo;
		if (transferInOrderConfirmInfo && transferInOrderConfirmInfo !== prevTransferInOrderConfirmInfo) {
			switch (transferInOrderConfirmInfo.type) {
			case cons.CONFIRM_TRANSFER_IN.SUCCESS:
				alert(loc.MSGTransferInItemReceived);
				this.props.history.push('/app/stock-transfer/in');
				break;
			case cons.CONFIRM_TRANSFER_IN.FAILURE:
				if (transferInOrderConfirmInfo.error.code) {
					alert(loc[transferInOrderConfirmInfo.error.code]);
				}
				break;
			}
		}

		if (receiveApi(transferPdfInfo, prevTransferPdfInfo, cons.PRINT_TRANSFER_ORDER)) {
			printJS({
				printable: transferPdfInfo.data.data,
				type: 'pdf',
				base64: true,
			});
		}

		const { approvalType, approvalUserId } = this.state;
		if (approvalType && approvalUserId) {
			const approvalInfo = this.props.approvalInfo;
			const prevApprovalInfo = prevProps.approvalInfo;
			const voidOrderInfo = this.props.voidOrderInfo;
			const prevVoidOrderInfo = prevProps.voidOrderInfo;
			if (approvalInfo && approvalInfo !== prevApprovalInfo) {
				switch (approvalInfo.type) {
				case cons.POST_APPROVAL.SUCCESS:
					this.setIsVerified(approvalInfo.data.is_verfied);
					break;
				case cons.POST_APPROVAL.FAILURE:
					this.setIsVerified(false);
					break;
				}
			}

			if (voidOrderInfo && voidOrderInfo !== prevVoidOrderInfo) {
				const { type } = this.getParams();
				let resetState = true;
				switch (voidOrderInfo.type) {
				case cons.VOID_ORDER.SUCCESS:
					UIkit.notification('The order is voided successfully.', { status: 'success' });
					this.props.history.push('/app/stock-transfer/' + (type === 'out' ? 'out' : 'in'));
					break;
				case cons.VOID_ORDER.FAILURE:
					UIkit.notification('The order is NOT voided.', { status: 'danger' });
					break;
				default:
					resetState = false;
					break;
				}

				if (resetState) {
					this.setState({
						showModalApproval: false,
						approvalType: null,
						approvalUserId: null,
						approvalRemark: null,
					});
				}
			}
		}
	}

	getParams(props) {
		if (!props) {
			props = this.props;
		}
		return {
			id: props.match.params.transactionNumber,
			orderType: props.match.params.orderType,
			type: props.match.params.type,
		};
	}

	fetchData() {
		const { id, orderType } = this.getParams();
		const param = {
			payload: {
				transaction_number: id,
				order_type: orderType,
			},
		};
		this.props.getTransferDetails(param);
	}

	isLoading() {
		const { transferDetailsInfo } = this.props;
		return transferDetailsInfo.isFetching;
	}

	setIsVerified(isVerified) {
		if (!isVerified) {
			return;
		} else {
			this.setState({
				showModalApproval: false,
			});
		}

		const { transferDetailsInfo } = this.props;
		const { approvalType, approvalUserId, approvalRemark } = this.state;
		let param = {
			payload: {}
		};

		switch (approvalType) {
		case 'voidOrder':
			param.payload = {
				transaction_number: transferDetailsInfo.data.transaction_number,
				is_approval_needed: true,
				is_approval_verified: isVerified,
				approval_user_code: approvalUserId,
				approval_remark: approvalRemark,
			};
			this.props.voidOrder(param);
			break;
		}
	}

	onClickCheckInHandler() {
		const { transferDetailsInfo } = this.props;
		const { orderType } = this.getParams();
		if (confirm(loc.MSGTransferInConfirm)) {
			const param = {
				payload: {
					source_transaction_number: transferDetailsInfo.data.transaction_number,
					order_type: orderType,
				},
			};
			this.props.confirmTransferInOrder(param);
		}
	}

	onClickVoidHandler(e) {
		e.preventDefault();
		this.setState({
			showModalApproval: true,
			approvalType: 'voidOrder',
		});
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
			approvalType: null,
		});
	}

	onRequestApprovalHandler({ user_id, code, remark }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
			approvalRemark: remark,
		});
		this.props.postApproval(param);
	}

	onClickPrintHandler() {
		const { id, orderType } = this.getParams();
		const param = {
			payload: {
				transaction_number: id,
				order_type: orderType,
			},
		};
		this.props.printTransferOrder(param);
	}

	render() {
		const { transferDetailsInfo, transferPdfInfo } = this.props;
		const { showModalApproval } = this.state;
		const { id, type } = this.getParams();
		const isLoading = this.isLoading();
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div className="uk-flex uk-flex-column">
						<Top name="stockTransfer">
							<div className="uk-position-absolute uk-position-top-right uk-margin-small-top">
								<Button
									text={ loc.print }
									theme="primary"
									disabled={ isLoading || transferPdfInfo.isFetching }
									onClick={ this.onClickPrintHandler }
								/>
							</div>
						</Top>
						<div>
							<div className="uk-margin-left uk-margin-remove-vertical uk-h3 uk-text-primary uk-text-uppercase">
								{ type === 'out' ? loc.transferOut : loc.transferIn }
							</div>
							<div className="uk-margin-left uk-margin-remove-top uk-h5 uk-text-muted">
								{ id }
							</div>
						</div>
						<div
							className="uk-height-large uk-overflow-auto"
							data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
						>
							<div className="uk-flex">
								<div className="uk-width-1-2 uk-padding uk-padding-remove-vertical">
									{
										type === 'out' ? (
											<Fragment>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.destinationLocation } *</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_desc_to }</div>
												</div>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.address }</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_address_to }</div>
												</div>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.phone }</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_phone_to }</div>
												</div>
											</Fragment>
										) : (
											<Fragment>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.sourceDestination } *</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_desc_from }</div>
												</div>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.address }</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_address_from }</div>
												</div>
												<div className={ styles.row }>
													<div className={ styles.title }>{ loc.phone }</div>
													<div className={ styles.content }>{ transferDetailsInfo.data.wh_phone_from }</div>
												</div>
											</Fragment>
										)
									}
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.salesman } *</div>
										<div className={ styles.content }>
											{ transferDetailsInfo.data.salesman_code }
											{ ' - ' }
											{ transferDetailsInfo.data.salesman_name }
										</div>
									</div>
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.remark } *</div>
										<div className={ classnames(styles.content, styles.remark) }>{ transferDetailsInfo.data.remark }</div>
									</div>
								</div>
								<div className="uk-width-1-2 uk-padding uk-padding-remove-vertical">
									<ul className="uk-list uk-list-divider uk-margin-remove">
										{
											transferDetailsInfo.data && transferDetailsInfo.data.transaction_items && transferDetailsInfo.data.transaction_items.map((item, index) => (
												<StockListItem
													key={ `page-stock-transfer-order-list-item-${index}` }
													index={ index }
													item={ item }
												/>
											))
										}
									</ul>
									<hr className="uk-margin-remove" />
									<div className="uk-text-primary uk-text-small uk-padding-small uk-padding-remove-horizontal">
										{
											transferDetailsInfo.data && transferDetailsInfo.data.item_count !== undefined && (
												`${loc.transferringItem}: ${transferDetailsInfo.data.item_count}`
											)
										}
									</div>
								</div>
							</div>

							{/* {
								type === 'out' && transferDetailsInfo.data.allow_void && (
									<div className={ classnames('uk-position-bottom-left', styles.voidOrder) }>
										<Button
											text={ loc.voidOrder }
											theme="primary"
											disabled={ isLoading }
											onClick={ this.onClickVoidHandler }
										/>
									</div>
								)
							} */}
							{
								type === 'in' && (
									<div className="uk-position-bottom-right">
										<Button
											text={ loc.continue }
											theme="primary"
											onClick={ this.onClickCheckInHandler }
										/>
									</div>
								)
							}
						</div>
					</div>
				</LoadingOverlay>
				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		transferDetailsInfo: state.transferDetailsInfo,
		transferInOrderConfirmInfo: state.transferInOrderConfirmInfo,
		approvalInfo: state.approvalInfo,
		voidOrderInfo: state.voidOrderInfo,
		transferPdfInfo: state.transferPdfInfo,
	}),
	(dispatch) => ({
		getTransferDetails: para => dispatch(getTransferDetails(para)),
		confirmTransferInOrder: para => dispatch(confirmTransferInOrder(para)),
		postApproval: (para) => dispatch(postApproval(para)),
		voidOrder: (para) => dispatch(voidOrder(para)),
		printTransferOrder: (para) => dispatch(printTransferOrder(para)),
	})
)(PageStockTransferOrder);