exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2pM09 {\n  padding-left: 20px;\n  padding-right: 20px;\n  cursor: pointer;\n}\n._2pM09 ._3htRJ {\n  display: inline-block;\n  width: calc(100% - 30px);\n  vertical-align: middle;\n}\n._2pM09 ._1E7Gb {\n  color: #BB0000;\n}\n._2pM09 ._3lJVD {\n  display: inline-block;\n  width: 30px;\n  vertical-align: middle;\n  text-align: right;\n  color: #BB0000;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_2pM09",
	"wrapper": "_3htRJ",
	"danger": "_1E7Gb",
	"icon": "_3lJVD"
};