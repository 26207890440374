import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import loc from 'Components/languages';
import Tab from 'Components/tab';
import Pagination from 'Components/pagination';
import { getSalesman } from 'Redux/actions';
import SalesmanListItem from './SalesmanListItem.jsx';
import styles from './Modal.less';

class ModalSalesman extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			keyword: '',
			currentTab: 'currentShop',
		};
		this.tabs = [
			'currentShop',
			'allShop',
		];
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const { salesmanType } = this.props;
		if (salesmanType) {
			this.setState({
				page: 1,
				keyword: '',
			}, this.fetchData);
		}
	}

	componentDidUpdate(prevProps) {
		const salesmanType = this.props.salesmanType;
		const prevSalesmanType = prevProps.salesmanType;
		if (salesmanType && salesmanType !== prevSalesmanType) {
			this.setState({
				page: 1,
				keyword: '',
			}, this.fetchData);
		}
	}

	fetchData() {
		const { salesmanType } = this.props;
		const { page, keyword, currentTab } = this.state;
		let param = {
			payload: {
				page,
			},
		};
		if (currentTab === 'currentShop') {
			param.payload.is_current_shop = true;
		}
		if (keyword) {
			param.payload.keyword = keyword;
		}
		if (salesmanType === 'optometrist') {
			param.payload.is_optometrist = true;
		} else {
			param.payload.is_salesman = true;
		}
		this.props.getSalesman(param);
	}

	onChangeTabHandler(tab) {
		this.setState({
			page: 1,
			currentTab: tab,
		}, this.fetchData);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	render() {
		const { isOpen, onToggle, onSelect, title, salesmanInfo } = this.props;
		const { currentTab, keyword } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ title }
				style={ this.modalStyle }
			>
				<div className={ styles.desc }>
					{ loc.salesmanPopupDesc }
				</div>
				<div className={ styles.searchContainer }>
					<form onSubmit={ this.onSubmitHandler }>
						<div className={ styles.search }>
							<input
								type="text"
								value={ keyword }
								placeholder={ loc.salesmanSearchHelpText }
								className={ styles.input }
								onChange={ this.onChangeKeywordHandler }
							/>
							<div className={ styles.icon }>
								<FontAwesomeIcon icon={ faSearch } />
							</div>
						</div>
						<div className={ styles.btnSearch }>
							<button
								type="submit"
								disabled={ salesmanInfo.isFetching }
								className="uk-button uk-button-small uk-button-secondary"
							>
								{ loc.search }
							</button>
						</div>
					</form>
				</div>
				<div className={ styles.tab }>
					<Tab
						tabs={ this.tabs }
						current={ currentTab }
						onChange={ this.onChangeTabHandler }
					/>
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							salesmanInfo.isFetching ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								salesmanInfo.data && salesmanInfo.data.data && salesmanInfo.data.data.map((salesman, index) => (
									<SalesmanListItem
										key={ `modal-salesman-info-${index}` }
										salesman={ salesman }
										onSelect={ onSelect }
									/>
								))
							)
						}
					</ul>
				</div>
				<Pagination
					data={ salesmanInfo.data }
					onPageChange={ this.onChangePageHandler }
				/>
			</Modal>
		);
	}
}

ModalSalesman.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	salesmanType: PropTypes.string.isRequired,
};

export default connect(
	(state) => ({
		salesmanInfo: state.salesmanInfo,
	}),
	(dispatch) => ({
		getSalesman: para => dispatch(getSalesman(para)),
	})
)(ModalSalesman);