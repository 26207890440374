import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import { extractDateFromDate } from 'Helpers/helpers';
import styles from './LotNoListItem.less';

class LotNoListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { lotNo, onSelect } = this.props;
		onSelect(lotNo);
	}

	render() {
		const { lotNo } = this.props;
		return (
			<li className={ styles.container } onClick={ this.onClickHandler }>
				<div className={ styles.wrapper }>
					{ lotNo.lot_no }

					<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
						<div className="uk-width-1-5">
							{ loc.qty }: { lotNo.quantity }
						</div>
						<div className="uk-width-2-5">
							{ loc.commitQty }: { lotNo.commit_quantity }
						</div>
						<div className={ classnames('uk-width-2-5', lotNo.is_expired && styles.danger) }>
							{ loc.expiryDate }: { lotNo.expiry_date ? extractDateFromDate(lotNo.expiry_date) : '' }
						</div>
					</div>
				</div>
				<div className={ styles.icon }>
					{
						lotNo.is_expired && (
							<FontAwesomeIcon size="lg" icon={ faInfoCircle } />
						)
					}
				</div>
			</li>
		);
	}
}

LotNoListItem.propTypes = {
	lotNo: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default LotNoListItem;